// this file is for misc experiment tracking that is not tied to a particular
//  page or resource

export const predictMyTestAnalyticsInfo = {
  CLICK_PREDICT_MY_TEST_BUTTON: {
    category: "predict-my-test",
    action: "click-predict-my-test-button",
  },
  CLOSE_PREDIECT_MY_TEST_DIALOG: {
    category: "predict-my-test",
    action: "close-predict-my-test-dialog",
  },
  SELECT_PREDICT_MY_TEST_SUBJECT: {
    category: "predict-my-test",
    action: "select-predict-my-test-subject",
  },
  SELECT_PREDICT_MY_TEST_UNIT: {
    category: "predict-my-test",
    action: "select-predict-my-test-unit",
  },
  SELECT_PREDICT_MY_TEST_CONTENT: {
    category: "predict-my-test",
    action: "select-predict-my-test-content",
  },
  CLICK_PREDICT_MY_TEST_GUIDE_LINK: {
    category: "predict-my-test",
    action: "click-predict-my-test-guide-link",
  },
};

export const apWorldPredictMyTestAnalyticsInfo = {
  CLICK_LINK: {
    category: "ap-world-predict-my-test",
    action: "click-ap-world-predict-my-test-link",
  },
};
